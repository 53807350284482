$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});

window.$.notify = function() {
  const html = $('#add-cart-success-template').html()
  console.log(html);
  const el = $(html);
  $('#notify-wrapper').append(el);
  el.find('.close-btn').click(function () {
    el.remove();
  })
  setTimeout(function () {
    el.remove();
  }, 3000)
};

$('.product-detail button.add-to-cart:not(.checkout)').click(function (e) {
  e.preventDefault();
  const txtId = $('.product-detail [name="txtId"]').val();
  const txtQuantity = $('.product-detail [name="txtQuantity"]').val();
  addCart(txtId, txtQuantity)
})

$('.category-page button.add-to-cart').click(function (e) {
  e.preventDefault();
  const txtId = $(this).data('id');
  const txtQuantity = 1;
  addCart(txtId, txtQuantity)
})


function addCart(txtId, txtQuantity) {
  $.post(window.cartUrl, { txtId, txtQuantity }).then(function (res) {
    rerenderCart(res)
    window.$.notify()
  })
}

function rerenderCart(html) {
  $( "#cart-header" ).replaceWith(html);
}
